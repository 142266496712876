<template>
  <div class="main-page">
    <el-form size="small" :model="ActivityForm" label-position="right" label-width="80px"
      class="form small-form" v-if="ActivityForm" @submit.native.prevent>
      <div class="modular">
        <p class="modular-title">活动流程</p>
        <div class="modular-content">
          <el-form-item label="活动议程：" prop="plans">
            <activity-plans :plans="ActivityForm.plans" @change="plansChange"></activity-plans>
          </el-form-item>
          <el-form-item label="活动嘉宾：" prop="guests">
            <activity-guests :guests="ActivityForm.guests" :guestList.sync="ActivityForm.guests">
            </activity-guests>
          </el-form-item>
          <el-form-item label="活动单位：" prop="units">
            <activity-units :units="ActivityForm.units" @change="ActivityForm.units = $event">
            </activity-units>
          </el-form-item>
        </div>
      </div>
      <!-- <div class="modular">
        <p class="modular-title">分享配置</p>
        <div class="modular-content">
          <el-form-item label="分享标题：" prop="share_title">
            <el-input type="text" v-model="ActivityForm.share_title" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="分享图片：" prop="share_image">
            <SingleMediaWall v-model="ActivityForm.share_image" :width="140" :height="140">
              <p slot="info" class="img-tips">
                建议上传尺寸比例为500*400，不上传图片时，默认使用系统自带图片
              </p>
            </SingleMediaWall>
          </el-form-item>
        </div>
      </div> -->
    </el-form>

  </div>
</template>
<script>
import SingleMediaWall from "@/modules/common/components/SingleMediaWall.vue";
import ActivityPlans from "../../components/ActivityList/ActivityPlans.vue";
import ActivityGuests from "../../components/ActivityList/ActivityGuests.vue";
import ActivityUnits from "../../components/ActivityList/ActivityUnits.vue";
export default {
  model: {
    prop: "form",
    event: "updateForm",
  },
  props: ["form", "isNext", "saveDetail", "saveLoading"],
  data() {
    return {};
  },
  watch: {
    "ActivityForm.plans": {
      deep: true,
      handler(val) {
        console.log(this.ActivityForm.plans, "ActivityForm.plans");
      },
    },
  },
  methods: {
    // 活动议程数据变更
    plansChange(val) {
      this.ActivityForm.plans = val;
    },
  },
  computed: {
    ActivityForm: {
      get() {
        return this.form;
      },
      set(val) {
        this.$emit("updateForm", val);
      },
    },
    is_next: {
      get() {
        return this.isNext;
      },
      set(val) {
        this.$emit("update:next", val);
      },
    },
  },
  components: {
    SingleMediaWall,
    ActivityPlans,
    ActivityGuests,
    ActivityUnits,
  },
};
</script>

<style lang="scss" scoped>
.img-tips {
  text-align: center;
  font-size: 12px;
  color: #c0c4cc;
  line-height: 24px;
}
.modular-content {
  margin-left: 11px;
}
</style>
