<!--
 * @Author: dongjia
 * @Date: 2022-01-18 11:34:03
 * @LastEditTime: 2022-01-18 18:14:40
 * @LastEditors: aleaner
 * @Description: 活动报名表单配置
 * @FilePath: \activity\components\ActivityList\ActivityJoinForms\ActivityJoinForms.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="confirm-content">
    <el-button type="primary" size="small" @click="AddForm">添加</el-button>
    <el-table :data="joinFormList" class="thead-light" stripe style="width: 100%;margin-top: 12px;"
      id="sortTable" row-key="timestamp">
      <!-- 拖拽排序 -->
      <el-table-column label="拖拽排序" :width="90">
        <span class="el-icon-sort my-handle cursor-pointer" />
      </el-table-column>
      <!-- 操作 -->
      <el-table-column label="操作" :width="120" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="EditRow(scope.row,scope.$index)">编辑</el-button>
          <el-button type="text" size="small" @click="DeleteRow(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
      <template v-for="(th, index) in thead">
        <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
          :min-width="th.minWidth || ''" :key="index">
          <template slot-scope="{row}">
            <!-- 类型 -->
            <span v-if="th.prop === 'type'">{{ typeText[row[th.prop]] | placeholder }}</span>
            <!-- 选项内容 -->
            <span
              v-else-if="th.prop === 'optionsText'">{{row.type === 'input'?'--':optionsText(row.options)}}</span>
            <!-- 是否必填 -->
            <span v-else-if="th.prop === 'required'">{{row[th.prop]?'是':'否'}}</span>
            <!-- 普通 -->
            <span v-else>
              {{ row[th.prop] | placeholder }}
            </span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <add-join-form-dialog v-model="openAddJoinForm" :formData="joinFormsForm" :titleText="dialogTitle"
      @joinFormChange="handleSelect">
    </add-join-form-dialog>
  </div>
</template>

<script>
import Sortable from "@/modules/page-renovation/js/Sortable.min.js";
import AddJoinFormDialog from "./AddJoinFormDialog.vue";
export default {
  props: {
    joinForms: {
      type: [Array, Object],
    },
  },
  data() {
    return {
      thead: [
        { label: "表单名称", prop: "name", minWidth: 110 },
        { label: "备注", prop: "description", minWidth: 150 },
        { label: "类型", prop: "type", minWidth: 90 },
        { label: "选项内容", prop: "optionsText", minWidth: 150 },
        { label: "是否必填", prop: "required", minWidth: 80 },
      ],
      joinFormsForm: {
        name: "",
        description: "",
        type: "", // 类型（input：填空，select：单选，multi-select:  多选）
        text_limit: 0, // 填空类型时，可输入字数限制
        options: [],
        required: 1, // 是否必填
        timestamp: 0, // 表单创建的时间戳
      },
      typeText: {
        input: "填空",
        select: "单选框",
        "multi-select": "多选框",
      },
      currentIndex: -1,
      openAddJoinForm: false,
      dialogTitle: "添加",
    };
  },
  watch: {
    openAddJoinForm(val) {
      if (!val) {
        this.joinFormsForm = {
          name: "",
          description: "",
          type: "", // 类型（input：填空，select：单选，multi-select:  多选）
          text_limit: 0, // 填空类型时，可输入字数限制
          options: [],
          required: 1, // 是否必填
          timestamp: 0, // 表单创建的时间戳
        };
      }
    },
  },
  mounted() {
    this.drag();
  },
  methods: {
    // 拖拽排序
    drag() {
      const el = document.querySelectorAll(
        "#sortTable .el-table__body > tbody"
      )[0];
      Sortable.create(el, {
        disabled: false,
        handle: ".my-handle",
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) {},
        onEnd: (e) => {
          this.$nextTick(() => {
            let arr = this.joinFormList;
            arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]); // 数据处理
          });
        },
      });
    },
    // 点击添加
    AddForm() {
      this.dialogTitle = "添加";
      this.currentIndex = -1;
      this.joinFormsForm = {
        name: "",
        description: "",
        type: "", // 类型（input：填空，select：单选，multi-select:  多选）
        text_limit: 0, // 填空类型时，可输入字数限制
        options: [],
        required: 1, // 是否必填
      };
      this.openAddJoinForm = true;
    },
    // 编辑
    EditRow(row, index) {
      this.dialogTitle = "编辑";
      this.currentIndex = index;
      this.joinFormsForm = row;
      this.openAddJoinForm = true;
    },
    // 点击移除
    DeleteRow(i) {
      this.joinFormList.splice(i, 1);
    },
    // 点击确认
    handleSelect(e) {
      console.log("position:", e);
      if (this.currentIndex !== -1) {
        this.joinForms.splice(this.currentIndex, 1, e);
      } else {
        this.joinForms.push({ ...e, timestamp: new Date().getTime() });
      }
    },
    // 选项内容格式转换
    optionsText(options) {
      let text = "";
      let optionsTexts = [];
      if (options.length > 0) {
        optionsTexts = options.map(
          (el) =>
            `${el.text}(需支付${
              el.pay_type
                ? `${el.pay_amount || 0}积分`
                : `￥${el.pay_amount || 0}`
            })`
        );
        text = optionsTexts.join("、");
      }
      return text;
    },
  },
  computed: {
    joinFormList: {
      get() {
        return this.joinForms;
      },
      set(val) {
        this.$emit("update:joinFormList", val);
      },
    },
  },
  components: { AddJoinFormDialog },
};
</script>

<style lang="scss" scoped>
.bottom-btn:hover {
  border: 1px dashed #3576ff;
}
</style>