<template>
  <div class="activity-units-container">
    <!-- Append unit group button-->
    <el-button class="append-button" @click="appendUnitGroup">添加</el-button>
    <!-- Unit group container -->
    <Draggable
      v-model="unitGroupList"
      group="unitGroup"
      @start="drag = true"
      @end="drag = false"
      :force-fallback="true"
      :scroll-sensitivity="100"
      handle=".group-sort"
      animation="250"
    >
      <div class="unit-group-container" v-for="(group, index) in unitGroupList" :key="index">
        <!-- Unit group header -->
        <div class="head-container">
          <!-- Editable input title -->
          <div class="title-container">
            <PopoverInputEdit v-model="group.type" nowrap>
              <template slot-scope="{ value }">
                <h2 class="title">{{ group.type }}</h2>
              </template>
            </PopoverInputEdit>
          </div>
          <!-- Append unit button -->
          <el-button size="small" @click="openAppendUnitDialog(index, 'append')">添加单位</el-button>
          <!-- Remove unit group button -->
          <el-button size="small" @click="remvoeUnitGroup(index)">删除</el-button>
          <!-- Draggable button(Group) -->
          <el-button size="small" icon="el-icon-rank" class="cursor-move group-sort"></el-button>
        </div>
        <!-- Unit containers -->
        <Draggable
          v-model="unitGroupList[index].units"
          group="unit"
          @start="drag = true"
          @end="drag = false"
          :force-fallback="true"
          :scroll-sensitivity="100"
          handle=".unit-sort"
          animation="250"
        >
          <div class="unit-container" v-for="(unit, uIndex) in group.units" :key="uIndex">
            <!-- Draggable button(Unit) -->
            <div class="padding-right-10px">
              <el-button type="text" icon="el-icon-sort" style="font-size: 14px" class="cursor-move unit-sort"></el-button>
            </div>
            <!-- Icon/image -->
            <div class="padding-right-10px">
              <!-- <el-image class="unit-image" :src="unit.icon" :previewSrcList="[...unit.icon]" fit="cover"></el-image> -->
              <SingleMediaWall :width="153" :height="86" v-model="unit.icon" :showDelete="false"></SingleMediaWall>
            </div>
            <!-- Unit name -->
            <div class="padding-right-10px" style="flex: 1; color: #333333">{{ unit.name }}</div>
            <!-- Edit button(Unit) -->
            <el-button class="edit-button" type="text" size="small" @click="editUnit(index, uIndex)"> 编辑</el-button>
            <!-- Vertical divider -->
            <el-divider direction="vertical"></el-divider>
            <!-- Remove button(Unit) -->
            <el-button class="edit-button" type="text" size="small" @click="removeUnit(index, uIndex)">删除</el-button>
          </div>
        </Draggable>
      </div>
    </Draggable>
    <!-- Append unit dialog -->
    <el-dialog
      :title="unitDialogStatu.mode === 'append' ? '新增单位' : unitDialogStatu.mode === 'edit' ? '编辑单位' : '单位'"
      :visible.sync="unitDialogStatu.show"
      width="460px"
      class="unit-dialog-container dialog-vertical"
    >
      <!-- Dialog form -->
      <el-form :rules="unitDialogRules" :model="unitDialogData" ref="unitDialogRef" size="medium" @submit.native.prevent>
        <el-form-item label="单位名称" prop="name">
          <el-input
            class="padding-left-10px"
            v-model="unitDialogData.name"
            :maxlength="unitDialogRules.name[0].max"
            show-word-limit
            :placeholder="unitDialogRules.name[1].message"
          ></el-input>
        </el-form-item>
        <!-- Icon/Image picker -->
        <el-form-item label="上传图片" prop="icon" class="margin-top-24px">
          <div class="image-picker-container">
            <SingleMediaWall v-model="unitDialogData.icon" :width="153" :height="86" ratio="343:194" :show-cut="true"></SingleMediaWall>
            <div class="info"><i class="el-icon-info"></i>{{`建议尺寸为${343 * 3}*${194 * 3}`}}</div>
          </div>
        </el-form-item>
        <!-- Commit buttons -->
        <el-form-item class="submit-container margin-top-24px">
          <el-button size="small" @click="unitDialogReset()">取消</el-button>
          <el-button size="small" type="primary" @click="unitDialogSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import SingleMediaWall from "@/modules/common/components/SingleMediaWall.vue";
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import Draggable from "vuedraggable";

export default {
  props: {
    /*
     * Sample data:
     * [
     *  type: "主办单位",
     *  units: [
     *            {
     *              icon: "https://xxx/cos/xxx/xxx",
     *              name: "广州 XX 有限公司"
     *            }
     *        ]
     * ]
     */
    units: {
      type: [Array, Object],
    },
  },
  data() {
    return {
      /* To store unit dialog status */
      unitDialogStatu: {
        // Visable
        show: false,
        // "edit" or "append"
        mode: "",
        // Unit group
        group: -1,
      },
      /* Unit dialog datas */
      unitDialogData: {
        // Unit name
        name: "",
        // Unit icon/image
        icon: "",
      },
      /* Unit dialog rules */
      unitDialogRules: {
        name: [
          // Length check
          {
            min: 1,
            max: 20,
            message: "单位长度不合法",
          },
          // Blank check
          {
            required: true,
            message: "请输入单位名称",
            trigger: "blur",
          },
        ],
        icon: [
          // Blank check
          {
            required: true,
            message: "必须选择一个图片",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    // console.log("---------单位初始数据--------");
    // console.log(this.units);
    // console.log("----------------------------");
  },
  methods: {
    /* Append a new unit group */
    appendUnitGroup() {
      this.unitGroupList.push({
        type: `新单位组${this.unitGroupList.length + 1}`,
        units: [],
      });
    },
    /* Remove unit group by group index */
    remvoeUnitGroup(index) {
      this.unitGroupList.splice(index, 1);
    },
    /* Remove unit by group index and unit index */
    removeUnit(index, uIndex) {
      this.unitGroupList[index].units.splice(uIndex, 1);
    },
    /* Open append unit by group index */
    openAppendUnitDialog(index) {
      this.unitDialogStatu.group = index;
      // Show
      this.unitDialogStatu.mode = "append";
      this.unitDialogStatu.show = true;
      // Set default value
      this.unitDialogData.name = "";
      this.unitDialogData.icon = "";
    },
    /* Open append unit by group index */
    editUnit(index, uIndex) {
      this.unitDialogStatu.group = index;
      this.unitDialogStatu.unit = uIndex;
      // Show
      this.unitDialogStatu.mode = "edit";
      this.unitDialogStatu.show = true;
      // Set current value
      this.unitDialogData.name = this.unitGroupList[this.unitDialogStatu.group].units[this.unitDialogStatu.unit].name;
      this.unitDialogData.icon = this.unitGroupList[this.unitDialogStatu.group].units[this.unitDialogStatu.unit].icon;
    },
    /* Reset when calcel */
    unitDialogReset() {
      this.unitDialogStatu.show = false;
      this.unitDialogStatu.group = -1;
      this.unitDialogStatu.unit = -1;
      this.unitDialogStatu.mode = "";
      this.unitDialogData.name = "";
      this.unitDialogData.icon = "";
    },
    /* Submit temp values to the main */
    unitDialogSubmit() {
      this.$refs["unitDialogRef"].validate((valid) => {
        if (valid) {
          // Edit
          if (this.unitDialogStatu.mode === "edit") {
            this.unitGroupList[this.unitDialogStatu.group].units[this.unitDialogStatu.unit].name = this.unitDialogData.name;
            this.unitGroupList[this.unitDialogStatu.group].units[this.unitDialogStatu.unit].icon = this.unitDialogData.icon;
          }
          // Append
          if (this.unitDialogStatu.mode === "append") {
            this.unitGroupList[this.unitDialogStatu.group].units.push({
              name: this.unitDialogData.name,
              icon: this.unitDialogData.icon,
            });
          }
          // Reset dialog form
          this.unitDialogReset();
        } else {
          return false;
        }
      });
    },
  },
  computed: {
    /* Unit group list */
    unitGroupList: {
      get() {
        
        return this.units;
      },
      set(val) {
        console.log(val,'val')
        this.$emit("update:unitList", val);
        this.$emit("change", val);
      },
    },
  },
  components: { SingleMediaWall, PopoverInputEdit, Draggable },
};
</script>

<style lang="scss" scoped>
/* Top container */
.activity-units-container {
  /* Custom margin(Margin top 24px) */
  .margin-top-24px {
    margin-top: 24px;
  }

  /* Custom padding(Padding left 10px) */
  .padding-left-10px {
    padding-left: 10px;
  }

  /* Custom padding(Padding right 10px) */
  .padding-right-10px {
    padding-right: 10px;
  }

  /* Custom cursor(Move) */
  .cursor-move {
    cursor: move;
  }

  /* Unit(Edit or append) dialog container */
  .unit-dialog-container {
    /* Container of submit buttons*/
    .submit-container {
      display: flex;
      flex-direction: row-reverse;
    }

    /* Image picker container of unit dialog */
    .image-picker-container {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
    }
  }

  /* Group container */
  .unit-group-container {
    border: 1px solid #dcdfe6;
    box-sizing: inherit;
    font-family: inherit;
    line-height: 36px;
    margin-top: 20px;

    /* Unit container */
    .unit-container {
      $padding-v: 10px;
      $padding-h: 15px;
      padding-top: $padding-v;
      padding-bottom: $padding-v;
      padding-left: $padding-h;
      padding-right: $padding-h;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      border-top: 1px solid #dcdfe6; // Border line

      /* Unit container calc */
      &:first-child {
        border-top: none;
      }

      /* Edit button */
      .edit-button {
        font-size: 14px !important;
        padding: 0px;
      }
    }

    /* Group head container */
    .head-container {
      background-color: #f1f1f1;
      display: flex;
      flex-direction: row;
      padding: 15px;

      /* Title container */
      .title-container {
        flex: 1;

        /* Title text */
        .title {
          min-width: 0;
          font-size: 16px;
          line-height: 36px;
        }
      }
    }
  }

  /* Append button(Group) */
  .append-button {
    font-size: 14px;
  }
}
</style>
