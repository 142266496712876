<template>
  <div class="confirm-content">
    <el-table :data="guestList" class="thead-light" stripe style="width: 100%">
      <!-- 操作 -->
      <el-table-column label="操作" :width="120" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="DeleteRow(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
      <template v-for="(th, index) in thead">
        <el-table-column :prop="th.prop" :label="th.label" :min-width="th.minWidth || ''" :key="index">
          <template slot-scope="scope">
            <!-- 头像 -->
            <SingleMediaWall v-if="th.prop === 'avatar'" v-model="guestList[scope.$index][th.prop]"
              :width="80" :height="80" borderRadius="50%" :showMask="false"></SingleMediaWall>
            <!-- 姓名 -->
            <span v-else-if="th.prop === 'name'">
              <el-input type="text" v-model="guestList[scope.$index][th.prop]"></el-input>
            </span>
            <!-- 简介 -->
            <span v-else>
              <el-input type="textarea" v-model="guestList[scope.$index][th.prop]" rows="4" maxlength="150" show-word-limit></el-input>
            </span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <el-button class="bottom-btn opacity-btn" @click="AddGuest">+ 添加嘉宾
    </el-button>
  </div>
</template>

<script>
import SingleMediaWall from "@/modules/common/components/SingleMediaWall.vue";
export default {
  props: {
    guests: {
      type: [Array, Object],
    },
  },
  data() {
    return {
      thead: [
        { label: "头像", prop: "avatar", minWidth: 100 },
        { label: "姓名", prop: "name", minWidth: 100 },
        { label: "简介", prop: "description", minWidth: 230 },
      ],
      guestsForm: {
        avatar:
          window.serverConfig.VUE_APP_ADMINURL +
          "/cyc/images/wxapps/icons/head@2x.png",
        name: "",
        description: "",
      },
    };
  },
  methods: {
    // 点击添加
    AddGuest() {
      this.guestList.push({ ...this.guestsForm });
    },
    // 点击移除
    DeleteRow(i) {
      this.guestList.splice(i, 1);
    },
  },
  computed: {
    guestList: {
      get() {
        return this.guests;
      },
      set(val) {
        this.$emit("update:guestList", val);
      },
    },
  },
  components: { SingleMediaWall },
};
</script>

<style lang="scss" scoped>
.bottom-btn {
  width: 100%;
  margin-top: 8px;
  border: 1px dashed rgba(217, 217, 217, 1);
  font-size: 16px;
  padding: 8px 0;
}

.bottom-btn:hover {
  border: 1px dashed #3576ff;
}
</style>
