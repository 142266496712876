<template>
  <div class="body" :style="!form.id ? { 'padding-top': '20px' } : ''">
    <div class="step-box">
      <!-- 操作步骤条 -->
      <ActivityStep
        :activityType="form.type"
        :type="$route.params.type"
        v-model="stepsStatus"
        @clickStatus="clickStepChange"
        :isDisabled="isStepClickDisabled"
      ></ActivityStep>
    </div>
    <div class="module-box" v-loading="loading || saveLoading">
      <!-- 流程第一部分 基本信息-->
      <div class="module" v-if="stepsStatus === 1">
        <DetailOne
          ref="activityBaseForm"
          v-model="form"
          :isNext="isNext"
          :next.sync="isNext"
          :positionOptions="positionOptions"
        />
        <DetailTwo
          v-if="!loading"
          v-model="form"
          :isNext="isNext"
          :next.sync="isNext"
        />
      </div>
      <!-- 流程第二部分 报名设置 或 其他设置-->
      <div class="module" v-if="stepsStatus === 2 && form.type === 1">
        <DetailJoin v-if="!loading" v-model="joinForm"></DetailJoin>
      </div>
      <div class="module" v-if="stepsStatus === 2 && form.type === 0">
        <DetailOther
          v-if="!loading && otherForm.id"
          v-model="otherForm"
          :isNext="isNext"
          :next.sync="isNext"
        >
        </DetailOther>
      </div>
      <!-- 流程第三部分 其他设置 或者 完成-->
      <div class="module" v-if="stepsStatus === 3 && form.type === 1">
        <DetailOther
          v-if="!loading && otherForm.id"
          v-model="otherForm"
          :isNext="isNext"
          :next.sync="isNext"
        >
        </DetailOther>
      </div>
      <div class="module" v-if="stepsStatus === 3 && form.type === 0">
        <DetailDone
          ref="detailDone"
          v-if="!loading && shareForm.id"
          :id="shareForm.id"
          v-model="shareForm"
        ></DetailDone>
      </div>
      <!-- 流程第四部分 完成-->
      <div class="module" v-if="stepsStatus === 4 && form.type === 1">
        <DetailDone
          ref="detailDone"
          v-if="!loading && shareForm.id"
          :id="shareForm.id"
          v-model="shareForm"
        ></DetailDone>
      </div>
      <FixedActionBar :z-index="2">
        <el-button size="medium" @click="cancelConfig">取消</el-button>
        <el-button
          v-show="
            ($route.params.type === 'add' && stepsStatus !== 1) ||
            isStepClickDisabled
          "
          type="primary"
          size="medium"
          @click="preStep"
        >
          上一步
        </el-button>
        <el-button
          type="primary"
          size="medium"
          @click="formSubmit"
          :loading="saveLoading"
        >
          {{ actionBarText }}
        </el-button>
      </FixedActionBar>
    </div>
  </div>
</template>

<script>
import {
  ActivityDetail,
  SaveActivity,
  positionLevel,
  saveOtherSettings,
  FinishedInfo,
  saveJoinSettings,
  updateShow,
  getActivity,
  JoinSettings,
  OtherSettings,
} from '../../api/activity-list/detail'
import DetailOne from './detail-one.vue'
import DetailTwo from './detail-two.vue'
import DetailOther from './detail-other.vue'
import DetailJoin from './detail-join.vue'
import DetailDone from './detail-done.vue'
import FixedActionBar from '@/base/layout/FixedActionBar'
import ActivityStep from '../../components/ActivityList/ActivityStep'

import Compare from '@/base/utils/object-compare.js'

export default {
  data() {
    return {
      loading: false,
      saveLoading: false,
      // 步骤条状态
      stepsStatus: 1,
      form: {
        id: 0,
        type: 1,
        name: '',
        activity_end_time: '',
        activity_start_time: '',
        addresses: [],
        cover: '',
        description: `<p>活动简要</p>
                      <p>活动时间</p>
                      <p>活动地点</p>
                      <p>人数限制</p>
                      <p>着装要求</p>
                      <p>报名说明</p>
                      <p>嘉宾信息</p>
                      <p>联系方式</p>
                      <p>温馨提示</p>`,
        dress: '',
        guests: [],
        images: [],
        is_live: 0,
        is_open_tips: 0,
        is_sign: 0,
        is_stage: 0,
        join_end_time: '',
        join_limit: '',
        can_all_cycuser_join: 1,
        is_pay_dues_join: 0,
        can_visitor_join: 0,
        check_visitor_join: 1,

        // 信息收集开关
        join_form_enable: 0, //是否开启报名表单

        // 信息收集（旧）
        join_form: [],

        // 信息收集（新）
        activityForm: null,
        activity_form_id: '',

        join_positions: [],
        join_price: 0,
        join_start_time: '',
        join_type: 0,
        jump_config: {
          name: '',
          appid: '',
          url: '',
          path: '',
          roomId: '', // 小程序直播房间号
        },
        jump_type: 'flow',
        live_for_visitor: 0, // 直播观看权限: 游客是否可看
        live_for_user: 0, // 直播观看权限: 非会员用户是否可看
        live_pay_enable: 0, // 是否开启付费观看
        live_pay_price: 0, // 付费观看的价格
        live_pay_type: 0, // 付费观看的支付类型
        lat: '',
        lng: '',
        // live_image: "",
        live_url: '',
        number_limit: 0,
        pay_type: 0,
        plans: [],
        seat_price: 0,
        share_image: '',
        share_title: '',
        sign_explain: '',
        stage_json: [],
        units: [],
        video: {},
        view_num: 0,
        activityJoinTime: '',
        activityTime: '',
        address_limit: 0,
        limited_addresses: [],
        seat_rule: 0,
        is_info_completed: 0,
        files: [],
      },
      // 其他设置表单
      otherForm: {
        id: 0,
        type: 0,
        // 是否创建话题
        auto_create_circle: 1,
        circle_post_enable: 1, // 是否开启发帖互动（0：不开启，1：开启）
        address_limit: 0, // 是否开启地址限制
        limited_addresses: [], // 限制的地址编码数组
        is_pay_dues_join: 0, // 未缴会费时是否可以报名参与报名(0:不可以，1:可以)
        can_visitor_join: 0, // 是否允许非会员报名
        check_visitor_join: 1, // 是否需要审核非会员报名
        is_info_completed: 0, // 是否需完善个人资料
        admin_user_qrcode: '', // 秘书二维码
        questions: [], // 答疑 json 数组 {"Q":"","A":""}
        photo_activity_id: '', // 关联云相册混淆 ID
        chat_qrcode: '', // 聊天群二维码
        chat_qrcode_remind_time: '', // 聊天群二维码提醒时间
        parking_tickets: [], // 停车票 json 数组 {"image":"","remark":""}
        dress: '', // 着装
        remark: '', // 备注说明
        share_title: '',
        share_image: '',
      },
      // 完成 / 分享表单
      shareForm: {
        id: 0,
        is_show: 1,
      },
      // 报名设置表单
      joinForm: {
        gov_join_limit: 0, //互联会员报名限制：0：禁止，1：允许
        gov_join_projects: [], //允许报名的互联协会id数组
        pay_free_for_gov_user: 0, //互联会员是否免费：0：否，1：是
        id: 0,
        join_type: 0, // 报名类型（0：免费、1：付费、2：保证金）
        pay_type: 0, // 付款方式（0：金钱，1：积分）
        join_price: 0, // 报名费用（金额单位为：分）
        early_price_enable: 0, // 是否开启早鸟价
        early_price: 0, // 早鸟价
        early_price_start_time: 0, // 早鸟价开始时间
        early_price_end_time: 0, // 早鸟价结束时间
        pay_free_for_cycuser: 0, // 是否开启会员无需付费
        pay_price_for_cycuser: 0, // 是否开启会员报名价格
        pay_cycuser_price_config: [], // 会员报名价格配置 json: {"position_id":0,"price":0,"price_type":0}
        seat_price: 0, // 占座费用(保证金)（金额单位：分）
        seat_rule: 0, // 保证金规则（0：取消时不返还，1：取消时按服务费收）
        join_start_time: 1669946584, // 报名开始时间
        join_end_time: 1670946584, // 报名结束时间
        join_limit: 100, // 报名人数限制
        can_all_cycuser_join: 1, // 是否全会员可报名
        join_positions: [], // 报名要求职位 json 数组
        is_stage: 0, // 是否开启阶段性报名（0：不开启，1：开启）
        stage_json: [], // 阶段性报名 json
        join_form_enable: 0, // 是否启用报名表单
        join_form: [], // 报名表单
        activityJoinTime: '',
        // 早鸟时间选择
        early_price_time: '',
        can_visitor_join: 0, // 是否允许非会员报名
      },
      // 架构列表
      positionOptions: [],
      isNext: false, // 下一步
      // 步骤条点击是否禁用 | 也表示未填写完毕活动资料
      isStepClickDisabled: true,
    }
  },
  methods: {
    handleLeave(to, from, next) {
      const formData = JSON.parse(JSON.stringify(this.getCurrentForm()))
      if (Compare(this.getCurrentShadow(), formData)) {
        next()
      } else {
        this.$msgbox
          .confirm('当前页面有修改，是否放弃更改？', '提示', {
            type: 'warning',
            distinguishCancelAndClose: true,
            confirmButtonText: '确定',
            cancelButtonText: !this.getCurrentForm().id ? '取消' : '保存',
          })
          .then((res) => {
            next() // Don't save!
          })
          .catch(async (action) => {
            if (action === 'cancel') {
              if (!this.getCurrentForm().id) {
                next(false) // Stay here??
              } else {
                // Save
                await this.formSubmit()
                next()
              }
            } else {
              // 关闭
              next(false)
            }
          })
      }
    },
    // 获取活动详情
    getBaseInfo() {
      this.loading = true
      getActivity({ id: this.form.id })
        .then((res) => {
          const { data } = res
          if (data.images && data.images.length) {
            let arr = []
            for (let i = 0; i < data.images.length; i++) {
              /* 处理空值 */
              if (data.images[i]) arr.push(data.images[i])
            }
            data.images = arr
          } else {
            data.images = []
          }
          if (JSON.stringify(data.video) === '[]') {
            data.video = {}
          } else if (JSON.stringify(data.video).indexOf('[') === 0) {
            data.video = data.video[0]
          }
          // 为空的话返回空数组
          if (!data.join_positions) {
            data.join_positions = []
          }
          if (!data.stage_json) {
            data.stage_json = []
          }
          data.files =
            data.files && data.files.length
              ? data.files.map((f) => ({ ...f, name: f.title }))
              : []
          this.form = { ...this.form, ...data }

          this.joinForm.type = this.form.type
          this.otherForm.type = this.form.type
          this.shareForm.type = this.form.type

          if (this.form.type == 0) {
            // 不显示报名表单栏目 和排位信息
            this.$parent.$parent.hiddenTab('SignUpList')
            this.$parent.$parent.hiddenTab('Ranking')
            this.$parent.$parent.hiddenTab('ListConfig')
          } else {
            this.$parent.$parent.showTab('SignUpList')
            this.$parent.$parent.showTab('Ranking')
            this.$parent.$parent.showTab('ListConfig')
          }
          this.$set(this.form, 'activityJoinTime', [
            data.join_start_time * 1000,
            data.join_end_time * 1000,
          ])
          this.$set(this.form, 'activityTime', [
            data.activity_start_time * 1000,
            data.activity_end_time * 1000,
          ])

          if (!this.isCycOrganization) {
            // 给个默认值
            this.form.can_visitor_join = 1
            this.joinForm.can_visitor_join = 1
            this.otherForm.can_visitor_join = 1
          }

          this.getPositionLevel()

          this.loading = false

          this.formShadow = JSON.parse(JSON.stringify(this.form))
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 获取报名设置
    getJoinSettings() {
      this.loading = true
      JoinSettings({ id: this.joinForm.id }).then((res) => {
        let data = res.data
        this.joinForm = { ...res.data, id: this.joinForm.id }
        if (data.join_start_time) {
          // 报名时间特殊处理

          this.$set(this.joinForm, 'activityJoinTime', [
            data.join_start_time * 1000,
            data.join_end_time * 1000,
          ])
        }
        if (data.early_price_start_time) {
          // 早鸟价时间兼容

          this.$set(this.joinForm, 'early_price_time', [
            this.joinForm.early_price_start_time * 1000,
            this.joinForm.early_price_end_time * 1000,
          ])
        }

        if (!this.isCycOrganization) {
          // 给个默认值
          this.joinForm.can_visitor_join = 1
        }

        this.joinForm.type = this.form.type

        this.joinFormShadow = JSON.parse(JSON.stringify(this.joinForm))

        this.loading = false
      })
    },
    //获取其他设置
    getOtherSettings() {
      this.loading = true

      OtherSettings({ id: this.otherForm.id })
        .then((res) => {
          this.otherForm = res.data
          this.loading = false

          if (!this.isCycOrganization) {
            // 给个默认值
            this.otherForm.can_visitor_join = 1
          }

          this.otherForm.type = this.form.type

          this.otherFormShadow = JSON.parse(JSON.stringify(this.otherForm))
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 获取分享设置
    getFinishedInfo() {
      // this.$refs.detailDone.getInfo();
      this.loading = true
      FinishedInfo({ id: this.shareForm.id }).then((res) => {
        this.shareForm = { ...res.data, id: this.shareForm.id }
        this.loading = false

        this.shareForm.type = this.form.type

        this.shareFormShadow = JSON.parse(JSON.stringify(this.shareForm))
      })
    },
    // 获取架构职务
    getPositionLevel() {
      positionLevel({
        is_list_tree: 0,
        is_show_all: 0,
        hashid_enable: 0,
        data_auth_filter: 0,
      })
        .then((res) => {
          this.positionOptions = res.data
        })
        .catch(() => {})
    },
    // 保存
    async formSubmit() {
      // 第一步 基本设置
      if (this.stepsStatus === 1) {
        if (this.$refs.activityBaseForm.formSubmit('ActivityForm')) {
          await this.save()
        }
      } else if (this.stepsStatus === 2) {
        if (this.form.type === 1) {
          // 线下活动第二步 报名设置表单保存
          await this.saveJoinForm()
        } else {
          // 线上活动第二步 其他设置表单保存
          await this.saveOtherForm()
        }
      } else if (this.stepsStatus === 3) {
        if (this.form.type === 1) {
          // 线下活动第三步 其他设置表单保存
          await this.saveOtherForm()
        } else {
          // 线上活动第三步 完成
          await this.saveDone()
        }
      } else if (this.stepsStatus === 4) {
        // 线下活动第四步 完成
        await this.saveDone()
      }
      return Promise.resolve()
    },
    // 取消
    cancelConfig() {
      this.$router.back()
    },
    // 保存基本设置
    save() {
      this.saveLoading = true
      if (
        this.form.plans.findIndex(
          (item) => item.time_text === '' || item.plan === ''
        ) !== -1
      ) {
        this.$message.error('请填写完整信息(活动议程)')
        this.saveLoading = false
        return
      }
      if (
        this.form.guests.findIndex(
          (item) =>
            item.avatar === '' || item.name === '' || item.description === ''
        ) !== -1
      ) {
        this.$message.error('请填写完整信息(活动嘉宾)')
        this.saveLoading = false
        return
      }

      let unitsIsValid = true
      this.form.units.forEach((el) => {
        if (el.type === '') {
          unitsIsValid = false
        } else {
          if (
            el.units.findIndex(
              (item) => item.icon === '' || item.name === '' || item.type === ''
            ) !== -1
          ) {
            unitsIsValid = false
          }
        }
      })
      if (!unitsIsValid) {
        this.$message.error('请填写完整信息(活动单位)')
        this.saveLoading = false
        return
      }
      //  删除不需要上传的属性
      this.form.addresses.forEach((item) => {
        this.$delete(item, 'activityTime')
      })
      const data = {
        ...this.form,
        is_restoring: this.$route.params.type === 'recover' ? 1 : 0,
      }
      data.join_form = JSON.stringify(data.join_form)
      data.join_positions = JSON.stringify(data.join_positions)
      data.images = JSON.stringify(data.images)
      data.video = JSON.stringify(data.video)
      data.plans = JSON.stringify(data.plans)
      data.guests = JSON.stringify(data.guests)
      data.units = JSON.stringify(data.units)
      data.jump_config = JSON.stringify(data.jump_config)
      data.brands = JSON.stringify(data.brands)
      data.stage_json = JSON.stringify(data.stage_json)
      data.files = data.files.map((el) => el.id).filter((el) => !!el)
      return SaveActivity(data)
        .then((res) => {
          // 获取新建或者保存的id
          this.joinForm.id = res.data
          this.otherForm.id = res.data
          // 赋值活动类型
          this.otherForm.type = data.type
          this.shareForm.id = res.data
          this.joinForm.id = res.data
          this.form.id = res.data

          if (this.$route.params.type !== 'add' && !this.isStepClickDisabled) {
            this.$message.success('保存成功')
          }
          this.saveLoading = false

          this.formShadow = JSON.parse(JSON.stringify(this.form))

          // 下一步
          this.nextStep(true, 'join')
        })
        .catch((err) => {
          this.saveLoading = false
        })
    },
    // 保存其他设置
    saveOtherForm() {
      this.saveLoading = true
      return saveOtherSettings({
        ...this.otherForm,
      })
        .then((res) => {
          this.saveLoading = false
          if (this.$route.params.type !== 'add' && !this.isStepClickDisabled) {
            this.$message.success('保存成功')
          }
          this.otherFormShadow = JSON.parse(JSON.stringify(this.otherForm))
          // 下一步
          this.nextStep(true, 'finished')
        })
        .catch((err) => {
          this.saveLoading = false
        })
    },
    // 保存 完成 | 分享 设置
    saveDone() {
      this.loading = true
      return updateShow({
        id: this.shareForm.id,
        is_show: this.shareForm.is_show,
      })
        .then((res) => {
          this.loading = false
          this.$message.success('保存成功!')
          this.shareFormShadow = JSON.parse(JSON.stringify(this.shareForm))
          // 新建或者恢复活动，跳转回活动列表
          if (!this.$route.params.id || this.$route.params.type === 'recover') {
            this.$router.push({ name: 'ActivityList' })
          } else {
            // 重新获取数据
            this.nextStep()
          }
        })
        .catch((err) => {
          this.loading = false
        })
    },
    // 编辑修改前备份一次
    getCurrentShadow() {
      switch (this.stepsStatus) {
        case 1:
          return this.formShadow
        case 2:
          return this.joinFormShadow
        case 3:
          return this.otherFormShadow
        case 4:
          return this.shareFormShadow
      }
    },
    // 正在编辑修改的
    getCurrentForm() {
      switch (this.stepsStatus) {
        case 1:
          return this.form
        case 2:
          return this.joinForm
        case 3:
          return this.otherForm
        case 4:
          return this.shareForm
      }
    },
    // 保存报名设置
    saveJoinForm() {
      this.saveLoading = true
      const { activityJoinTime, ...others } = this.joinForm
      return saveJoinSettings(others)
        .then((res) => {
          this.saveLoading = false
          if (this.$route.params.type !== 'add' && !this.isStepClickDisabled) {
            this.$message.success('保存成功')
          }

          this.joinFormShadow = JSON.parse(JSON.stringify(this.joinForm))

          // 下一步
          this.nextStep()
        })
        .catch((err) => {
          this.saveLoading = false
        })
    },
    // 执行步骤条下一步
    nextStep(isPost, type) {
      if (!this.form.type && this.stepsStatus >= 3) {
        if (this.isStepClickDisabled) {
          // 步骤已经填写完成
          this.changeUrlQuery()
          this.isStepClickDisabled = false
          // 顶部tab 可以显示了
          this.$parent.$parent.setRouterTabs(true)
        }

        return
      }
      if (this.form.type && this.stepsStatus >= 4) {
        // 步骤已经填写完成
        if (this.isStepClickDisabled) {
          // 步骤已经填写完成
          this.changeUrlQuery()
          this.isStepClickDisabled = false
          // 顶部tab 可以显示了
          this.$parent.$parent.setRouterTabs(true)
        }

        return
      }

      if (this.$route.params.type === 'add' || this.isStepClickDisabled) {
        this.stepsStatus++
      } else {
        // 刷新活动配置
        this.refreshActivity()
      }

      // 判断是否需要请求对应步骤的接口
      if (isPost) {
        if (type === 'finished') {
          this.getFinishedInfo()
        } else if (type === 'join' && this.form.type === 1) {
          this.getJoinSettings()
        }
      }
    },
    // 上一步
    preStep() {
      this.stepsStatus--
    },
    // 刷新活动
    refreshActivity() {
      console.log('刷新')
      this.clickStepChange(this.stepsStatus)
    },
    // 点击步骤条状态改变，或者刷新
    clickStepChange(current) {
      this.handleLeave(null, null, (bol) => {
        // 改变步骤条
        this.stepsStatus = current
        // 判断步骤执行哪一步
        switch (current) {
          case 1:
            // 基本信息
            this.getBaseInfo()

            break
          case 2:
            // 报名设置 或者 活动设置

            if (this.form.type) {
              this.getJoinSettings()
            } else {
              this.getOtherSettings()
            }

            break
          case 3:
            // 其他设置 或者 分享设置
            if (this.form.type) {
              this.getOtherSettings()
            } else {
              this.getFinishedInfo()
            }

            break
          case 4:
            // 分享设置
            this.getFinishedInfo()

            break
        }
      })
    },
    // 动态修改地址栏步骤参数
    changeUrlQuery() {
      let path = this.$router.history.current.path
      // 地址栏的步骤参数值赋值
      path = path.slice(0, path.length - 1) + '0'
      this.$router.push({ path })
    },
  },
  created() {
    console.log(this.$parent.$parent, 'parent')

    this.form.id = this.$route.params.id
    this.joinForm.id = this.$route.params.id
    this.otherForm.id = this.$route.params.id
    this.shareForm.id = this.$route.params.id

    if (this.form.id) {
      let step = this.$route.params.create_step
      if (step == 0) {
        this.getBaseInfo()
      } else {
        // 方法数组
        let fnArray = [
          'getBaseInfo',
          'getJoinSettings',
          'getOtherSettings',
          'getFinishedInfo',
        ]
        // 需要执行的方法
        this.realFn = fnArray.slice(0, step)
        this.realFn.forEach((fnName) => this[fnName]())
      }

      // 获取内容
    } else {
      this.getPositionLevel()

      if (!this.isCycOrganization) {
        // 给个默认值
        this.form.can_visitor_join = 1
        this.otherForm.can_visitor_join = 1
      }
    }

    if (
      Number(this.$route.params.create_step) !== 0 &&
      this.$route.params.type !== 'add'
    ) {
      this.stepsStatus = Number(this.$route.params.create_step)
      // 不显示tab栏目
      this.$parent.$parent.setRouterTabs(false)
    } else {
      // 允许步骤条可以点击
      this.isStepClickDisabled = false
      // 显示 tab
      this.$parent.$parent.setRouterTabs(true)
    }
  },
  mounted() {
    let that = this
    /**
     * 从Firefox 4、 Chrome 51、Opera 38 和Safari 9.1开始，通用确认信息代替事件返回的字符串。
     * 也就是不能自定义
     * */
    window.onbeforeunload = function (e) {
      console.log('on before unload')
      e = e || window.event // 兼容IE8和Firefox 4之前的版本
      const formData = JSON.parse(JSON.stringify(this.getCurrentForm()))
      if (Compare(that.getCurrentShadow(), formData)) {
        console.log('no change')
      } else {
        console.log('modified')
        if (e) {
          e.returnValue = '当前页面有修改，是否放弃更改？'
        } // Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
        return '当前页面有修改，是否放弃更改？'
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.handleLeave(to, from, next)
  },
  beforeRouteEnter(to, from, next) {
    const type = to.params.type
    switch (type) {
      case 'recover':
        to.meta.title = '恢复活动'
        break
      case 'add':
        to.meta.title = '新增活动'
        break
      default:
        to.meta.title = '活动详情'
    }
    next()
  },
  watch: {
    isNext(val) {
      console.log(val)
    },
  },
  components: {
    DetailOne,
    DetailTwo,
    DetailOther,
    FixedActionBar,
    ActivityStep,
    DetailDone,
    DetailJoin,
  },
  computed: {
    // 底部按钮文字
    actionBarText() {
      if (this.$route.params.type === 'recover') {
        return '恢复活动'
      }
      if (this.$route.params.type === 'add' || this.isStepClickDisabled) {
        // 线上活动步骤
        if (this.stepsStatus < 3 && this.form.type === 0) {
          return '下一步'
          // 线下活动步骤
        } else if (this.stepsStatus < 4 && this.form.type === 1) {
          return '下一步'
        }
      }

      return '保存'
    },
    isCycOrganization() {
      return this.$store.getters.tenant.features.includes('cyc_organization')
    },
  },
}
</script>

<style>
.body {
  min-height: calc(100vh - 190px);
}

.step-box {
  margin-bottom: 32px;
  margin-top: 32px;
}
</style>
