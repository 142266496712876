<template>
    <el-dialog class="dialog-vertical" width="1200px" @open="init" top="10vh" :visible.sync="openDialog" center
                :append-to-body="appendToBody">
        <div class="list-page">
            <list-filter :filterForm="filterForm" :uploadFilter="ok" />
            <div class="list-content" v-loading="loading">
                <el-table :data="tbody" class="thead-light" stripe style="width: 100%;" height="400">
                    <!-- 操作 -->
                    <template v-for="(th, index) in thead">
                        
                        <el-table-column v-if="th.type === 'checkbox'"  :label="th.label"
                            :min-width="th.minWidth || ''"  :key="index">
                            <template slot-scope="scope">
                                <el-radio v-model="radio" :label="scope.row">&#160;</el-radio>
                            </template>
                       
                        </el-table-column>
                        <el-table-column v-else show-overflow-tooltip :prop="th.prop" :label="th.label"
                            :min-width="th.minWidth || ''" :sortable="th.sortable" :key="index">
                        </el-table-column>
                    </template>
                </el-table>
            </div>
            <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
        </div>
        <template #footer>
           <el-button @click="openDialog = false">取消</el-button>
           <el-button :disabled="JSON.stringify(radio) === '{}'" type="primary" @click="change">确定</el-button>
        </template>
    </el-dialog>
</template>
  
<script>
import listFilter from "./ListFilter.vue";
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit";
import Pagination from "@/base/components/Default/Pagination";
import ListImage from "@/base/components/List/ListImage";
import ListStatusText from "@/base/components/List/ListStatusText";
import { getVenueList } from '@/modules/venue/api/'
export default {
    name: 'DadgeDialog',
    model: {
        prop: "open",
        event: "closeDialog",
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        appendToBody: Boolean,
        // 把列表所有字段都抛出，否则只有 {id, name}
        selectFullData: Boolean
    },
    data() {
        return {
            loading: false, //加载
            thead: [
                {
                    label: '勾选',
                    minWidth:60,
                    type: 'checkbox',
                },
                {
                    label: '场地名称',
                    prop: 'name',
                    type: 'slot',
                    minWidth: 180,
                },
                {
                    label: '所属组织',
                    prop: 'organization_name',
                    minWidth: 180,
                },
                // {
                //   label: 'TODO：分组',
                //   prop: 'organization_name',
                // },
                {
                    label: '场地分类',
                    prop: 'category_name',
                    minWidth: 100,
                },
                {
                    label: '容纳人数',
                    prop: 'people_number',
                },
                {
                    label: '场地设施',
                    prop: 'facility_names',
                    minWidth: 200,
                },
                {
                    label: '权限',
                    prop: 'reserve_text',
                    minWidth: 160,
                },
                // {
                //     label: '开放时间',
                //     prop: 'reserve_time_text',
                //     // type: 'slot'
                // },
                {
                    label: '状态',
                    prop: 'status_text',

                },
            ],
            //筛选对象
            filterForm: {
                is_from_activity:1,
                status: -1, // 预定状态
                venue_facility_id: '', // 设施 ID
                keyword: '',
                page: 1,
                page_size: 15,
                order_by: 'id',
                is_desc: 0,
                organization_id:'',
                start_time: -1,
                end_time: -1,
                tab: 'normal',
                min_people_number: -1,
                max_people_number: -1,
            },
            pageData: {
                page_size: 15,
            },
            tbody: [],
            radio:{},
        };
    },

    methods: {
        // 获取勋章列表
        getDadgeIndex(pageData) {
            this.tbody = [];
            this.pageData = {};
            this.loading = true;
            this.radio = {}
            getVenueList(pageData).then((res) => {
                const { data } = res;
                // this.exchangeParentList(data.data);
                this.tbody = data.data;
                this.pageData = data;
                // this.filters[data.tab] = { ...this.filterForm };
                this.loading = false;
            }).catch((err) => {
                this.loading = false;
            });
        },
        // 筛选回调
        ok(e) {
            const pageData = {};
            this.filterForm = { ...this.filterForm, ...e, page: 1 };
            this.getDadgeIndex(this.filterForm);
        },
        // 分页查询
        changPage(e) {
            this.filterForm.page_size = e.page_size;
            const pageData = { ...this.filterForm, ...e };
            this.getDadgeIndex(pageData);
        },
        init() {
            this.getDadgeIndex(this.filterForm)
        },
        change(){
            this.openDialog = false
            console.log(this.radio);
            if (this.selectFullData) {
                this.$emit('change', this.radio)
            } else this.$emit("change",this.radio.address)
        },
    },

    computed: {
        openDialog: {
            get() {
                return this.open;
            },
            set(val) {
                this.$emit("closeDialog", val);
            },
        },
    },
    components: { listFilter, Pagination, PopoverInputEdit, ListImage, ListStatusText },

};
</script>
  
<style lang="scss" scoped>

</style>
  