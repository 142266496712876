<!--
 * @Author: dongjia
 * @Date: 2022-01-18 14:08:19
 * @LastEditTime: 2022-01-18 18:20:27
 * @LastEditors: aleaner
 * @Description: 添加报名表单弹窗
 * @FilePath: \activity\components\ActivityList\ActivityJoinForms\AddJoinFormDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog :title="`${titleText}信息`" :visible.sync="open" width="600px" :show-close="true"
    class="dialog-vertical" append-to-body @close="close('joinForm')">
    <el-form size="small" :model="joinForm" :rules="rules" ref="joinForm" label-width="90px" @submit.native.prevent>
      <el-form-item label="内容名称：" prop="name">
        <el-input style="width: 260px" v-model="joinForm.name" placeholder="请填写表单名称">
        </el-input>
      </el-form-item>
      <el-form-item label="备注：" prop="description">
        <el-input style="width: 260px" type="textarea" v-model="joinForm.description"
          :autosize="{ minRows: 6 }" placeholder="请填写备注">
        </el-input>
      </el-form-item>
      <el-form-item label="类型：" prop="type">
        <el-select style="width: 260px" v-model="joinForm.type" placeholder="请选择表单类型">
          <el-option v-for="(el, index) in formTypeOptions" :key="index" :label="el.label" :value="el.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="字数限制：" v-if="joinForm.type === 'input'" prop="text_limit">
        <el-input-number style="width: 260px" v-model="joinForm.text_limit" :min="1" label="字数限制">
        </el-input-number>
      </el-form-item>
      <el-form-item label="选项内容：" prop="options" v-if="joinForm.type && joinForm.type !== 'input'">
        <!-- 添加选项模块 -->
        <ActivityJoinFormOptions :formOptions="joinForm.options" />
      </el-form-item>
      <el-form-item label="是否必填：" prop="required">
        <el-radio v-model="joinForm.required" :label="1">是</el-radio>
        <el-radio v-model="joinForm.required" :label="0">否</el-radio>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" @click="submit('joinForm')">确定</el-button>
      <el-button @click="open = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import ActivityJoinFormOptions from "./ActivityJoinFormOptions.vue";

export default {
  model: {
    prop: "openDialog",
    event: "closeDialog",
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      required: true,
    },
    titleText: {
      type: String,
      default: "添加",
    },
  },
  components: { ActivityJoinFormOptions },
  data() {
    return {
      joinForm: {
        name: "",
        description: "",
        type: "", // 类型（input：填空，select：单选，multi-select:  多选）
        text_limit: 0, // 填空类型时，可输入字数限制
        options: [],
        required: 1, // 是否必填
        timestamp: 0, // 表单创建的时间戳
      },
      rules: {
        name: [{ required: true, message: "请输入表单名称", trigger: "blur" }],
        type: [
          { required: true, message: "请选择表单类型", trigger: "change" },
        ],
        text_limit: [
          {
            required: true,
            type: "number",
            min: 0,
            message: "请输入字数限制",
            trigger: "blur",
          },
        ],
        options: [
          {
            required: true,
            type: "array",
            message: "选项内容不能为空",
            trigger: "change",
          },
        ],
      },
      formTypeOptions: [
        { label: "填空", value: "input" },
        { label: "单选", value: "select" },
        { label: "多选", value: "multi-select" },
      ],
      dialogTitle: "添加",
    };
  },
  watch: {
    formData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          console.log(val, "formData");
          this.joinForm = {
            ...this.joinForm,
            ...JSON.parse(JSON.stringify(val)),
          };
        });
      },
    },
  },
  computed: {
    open: {
      get() {
        return this.openDialog;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
  methods: {
    submit(formName) {
      let formData = JSON.parse(JSON.stringify(this.joinForm));
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("joinFormChange", formData);
          this.open = false;
        } else {
          return false;
        }
      });
    },
    close(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>