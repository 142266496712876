<template>
  <el-dialog class="dialog-vertical" title="选择表单" width="900px" :visible.sync="openDialog" close-on-click-modal close-on-press-escape append-to-body>
    <list-layout ref="activityListDialog" :on-fetch="onFetch" :filter-form="filterForm" :thead="thead" role="dialog"
    @command="onCommand">
      <template #filter>
        <activity-form-list-filter :filter-form="filterForm" :upload-filter="onQuery"></activity-form-list-filter>
      </template>
      <template #top>
        <el-button
            class="top-btn"
            type="primary"
            size="small"
            @click="toDesign()"
        >创建表单</el-button
        >
        <el-button
            class="top-btn"
            type="plain"
            size="small"
            @click="refresh()"
        >刷新</el-button
        >
      </template>
<!--      <template #item_activities_count="{ row }">-->
<!--        <span>{{ row.activities_count }}</span>-->
<!--        <el-button-->
<!--            v-if="row.activities_count > 0"-->
<!--            @click.stop="viewActivities(row)"-->
<!--            style="margin-left: 10px"-->
<!--            type="text"-->
<!--        >查看</el-button-->
<!--        >-->
<!--      </template>-->
<!--      <activity-form-activities-->
<!--          :show="activityDialog.show"-->
<!--          v-bind="activityDialog.data || {}"-->
<!--          @close="activityDialog.show = false"-->
<!--      ></activity-form-activities>-->
    </list-layout>

    <template #footer>
      <el-button size="small" @click="openDialog = false">取消</el-button>
      <el-button size="small" type="primary" @click="submit('Form')"
      >确定</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import ListLayout from '../../../../base/layout/ListLayout'
import ActivityFormListFilter from './ActivityFormListFilter'
import {getActivityFormList} from '../../api/activity-form'

import activityForm from '../../mixin/activityForm'

export default {
  name: 'activity-form-list-dialog',
  mixins: [activityForm],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    activities: Array,
  },
  data() {
    return {
      loading: false, // 加载
      thead: [
        { type: 'radio', width: 60 },
        { label: '表单ID', prop: 'id', width: 100 },
        { label: '表单名称', prop: 'name', minWidth: 100 },
        {
          label: '已选择活动数',
          prop: 'activities_count',
          minWidth: 100,
          // type: 'slot',
          sortable: true,
        },
        {
          label: '创建时间',
          prop: 'create_time',
          minWidth: 160,
          sortable: true,
        },
        {label: "操作", type: "operation", width: 100, operations: [{command: 'copy', text: '复制'}]}
      ],
      // 筛选对象
      filterForm: {
        page: 1,
        page_size: 15,
        min_activity: -1,
        max_activity: -1,
        start_time: -1,
        end_time: -1,
        name: '',
        order_by: 'id', //id,create_time,activities_count
        is_desc: 1,
      },

      activityDialog: {
        show: false,
        data: null,
      },
    }
  },
  mounted() {
    let that = this
    // onShow
    this._visibilitychange_addEventListener = function() {
      if (document.visibilityState === 'visible') {
        console.log('浏览器的当前页签onShow时，do something')
        // that.onQuery()
      }
    }
    document.addEventListener('visibilitychange', this._visibilitychange_addEventListener)
  },
  destroyed() {
    document.removeEventListener('visibilitychange', this._visibilitychange_addEventListener)
  },
  methods: {
    refresh() {
      this.onQuery()
    },
    onQuery(query = {}) {
      console.log(this.$refs.activityListDialog)
      this.$refs.activityListDialog.getList({...query, page: 1})
    },
    onFetch(query = {}) {
      return getActivityFormList(query)
    },
    viewActivities(row) {
      this.activityDialog.data = row
      this.activityDialog.show = true
    },
    submit() {
      const idList = this.$refs.activityListDialog?.selectArr

      if (idList.length) {
        // TODO：开发注意：是单选就拿最后一个，多选就全部
        const detailList = idList
            .map((id) => {
              if (this.$refs.activityListDialog) return this.$refs.activityListDialog?.getDetailById(id) || null
              else return null
            })
            .filter((item) => !!item)

        this.$emit('confirm', detailList)
      } else {
        this.$message.info('没有检测到勾选的数据！')
      }
    }
  },
  computed: {
    openDialog: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('close', val)
      },
    },
  },
  components: {
    ActivityFormListFilter,
    ListLayout,
  },
}
</script>

<style lang="scss" scoped></style>
