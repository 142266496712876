<template>
  <div class="album-content">
    <div class="table-content">
      <el-table class="thead-light" stripe :data="Tbody" v-loading="loading" row-key="id">
        <template v-for="th in thead">
          <el-table-column
            :prop="th.prop"
            :label="th.label"
            :minWidth="th.minWidth"
          >
            <template slot-scope="scope">
              <!-- 封面 -->
              <span v-if="th.prop === 'cover'">
                <ListImage :src="scope.row.cover"></ListImage>
              </span>
              <span v-else-if="th.prop === 'photo_image_count'">
                {{(scope.row.photo_image_count?scope.row.photo_image_count:0) + (scope.row.photo_video_count?scope.row.photo_video_count:0)}}
              </span>
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <el-button type="primary" @click="showDialog = true">{{photoId ? '修改' :'请选择'}}</el-button>
    <!-- 选择相册弹窗 -->
    <SelectDataDialog
      v-model="showDialog"
      @change="changeSelect"
      componentType="st-activity-photo"
    ></SelectDataDialog>
  </div>
</template>
<script>
import SelectDataDialog from "./SelectDataDialog";
import ListImage from "@/base/components/List/ListImage";
import { detailForActivity } from "../../api/activity-list/detail";
export default {
  name: "SelectAlbum",
  model: {
    prop: "photo_activity_id",
    event: "changeId",
  },
  props: {
    // 职务架构配置
    photo_activity_id: {
      type: Number | String,
    },
  },
  components: { SelectDataDialog, ListImage },
  mounted() {
    this.getDetail(this.photoId);
  },
  computed: {
    // 关联相册混淆id
    photoId: {
      get() {
        return this.$props.photo_activity_id;
      },
      set(val) {
        this.$emit("changeId", val);
      },
    },
  },
  watch: {},

  data() {
    return {
      Tbody: [],
      thead: [
        { label: "封面图", prop: "cover", minWidth: 100 },
        { label: "相册名称", prop: "activity_name", minWidth: 200 },
        { label: "数据类型", prop: "type_text", minWidth: 100 },
        { label: "图片数量", prop: "photo_image_count", minWidth: 100 },
        { label: "分类名称", prop: "category_name", minWidth: 100 },
      ],
      showDialog: false,
      loading: false,
    };
  },
  methods: {
    // 改变选择
    changeSelect(selectItem) {
      // 选中的相册id
      this.photoId = selectItem[0].id;
      console.log(selectItem[0].id)
      this.getDetail(selectItem[0].id);
    },
    // 获取关联的相册详情
    getDetail(id) {
      if (id) {
        this.loading = true;
        detailForActivity({ id })
          .then((res) => {
            this.Tbody = [res.data];
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.table-content {
  padding: 16px;
  background-color: #f5f7fa;
  margin-bottom: 10px;
}
</style>
