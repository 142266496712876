<template>
  <div>
    <div class="add-subjects">
      <div class="subject-list" v-show="myValue.length !== 0">
        <draggable
          v-model="myValue"
          animation="500"
          @end="changeSort"
          draggable=".draggable"
        >
          <div
            class="single-subject draggable"
            v-for="(subject, index) in myValue"
            :key="index"
          >
            <el-form :model="myValue[index]" :rules="rules" label-width="90px" @submit.native.prevent>
              <div class="item">
                <!-- 操作栏 -->
                <div class="subject-btn">
                  <img src="../../assets/images/operation.png" alt="" />
                  <i @click="removeLine(index)" class="el-icon-delete"></i>
                </div>
                <div class="content">
                  <el-form-item
                    label="停车票："
                    prop="image"
                    style="margin-bottom: 20px"
                  >
                    <SingleMediaWall
                      v-model="myValue[index].image"
                      :width="150"
                      :height="100"
                    >
                    </SingleMediaWall>
                  </el-form-item>
                  <el-form-item prop="remark" label="备注：">
                    <div class="label">
                      <el-input
                        height="80px"
                        type="textarea"
                        class="textarea"
                        v-model="myValue[index].remark"
                        placeholder="请输入备注"
                      ></el-input>
                    </div>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
        </draggable>
      </div>
      <el-button
        class="subject-add-btn"
        type="primary"
        size="small"
        @click="addLine"
        >添加停车票</el-button
      >
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import SingleMediaWall from "@/modules/common/components/SingleMediaWall.vue";

export default {
  name: "ParkingTickets",
  components: { draggable, SingleMediaWall },
  model: {
    prop: "value",
    event: "changeValue",
  },
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      loading: true,
      myValue: [],
      rules: {
        image: [{ required: true, message: "请上传停车票", trigger: "blur" }],
        remark: [{ required: true, message: "请填写备注", trigger: "blur" }],
      },
    };
  },
  watch: {
    value: {
      handler(val) {
        this.myValue = val;
      },
      immediate: true,
    },
  },
  computed: {},
  created() {},
  methods: {
    // 移除
    removeLine(i) {
      this.$confirm("是否删除此停车票?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.myValue.splice(i, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {});
    },
    // 添加
    addLine() {
      this.myValue.push({ image: "", remark: "" });
      this.$emit("changeValue", this.myValue);
    },

    // 顺序改变
    changeSort() {
      this.$emit("changeValue", this.myValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-subjects {
  .img-tips {
    width: 194px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #999;
    line-height: 12px;
    margin-top: 10px;
  }
  .subject-list {
    width: 560px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: #f5f7fa;
    .single-subject {
      display: flex;
      justify-content: space-between;
      .item {
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 5px;
        color: #909399;
        .subject-btn {
          padding: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid rgba(244, 244, 245, 1);
          .el-icon-delete {
            cursor: pointer;
          }
        }
        .content {
          margin: 22px 12px;

          .label {
            display: flex;
            margin-bottom: 20px;
            color: #606266;
            span {
              width: 90px;
              text-align: right;
              margin-right: 8px;
            }
            .tips {
              width: 194px;
              display: flex;
              align-items: center;
              font-size: 12px;
              color: #999;
              line-height: 12px;
              margin-left: 10px;
            }
            .el-input {
              flex: 1;
              max-width: none;
            }
            .textarea {
              flex: 1;
              max-width: none;
            }
            ::v-deep .el-textarea__inner {
              height: 80px;
            }
          }
        }
      }
      .flex {
        display: flex;
        padding-top: 10px;
        span {
          width: 15px;
          display: inline-block;
          margin-right: 10px;
        }
      }
    }

    .subject-del-btn {
      width: 30px;
      text-align: right;
      cursor: pointer;
    }
  }

  .subject-add-btn {
    margin-top: 10px;
  }
}
.el-icon-delete:hover {
  color: red;
}
.el-form {
  width: 100%;
  margin: 5px 0;
}
</style>
