<template>
  <el-dialog :title="form.position.length?'编辑阶段性报名':'新增阶段性报名'" :visible.sync="openDialog" width="600px"
    :show-close="true" class="dialog-vertical" @close="cancel">
    <el-form class="dialog-form" size="medium" label-width="85px" :model="form" :rules="rules" ref="Form" @submit.native.prevent>
      <el-form-item style="margin-bottom: 20px" label="会内职务：" prop="position">
        <el-table stripe class="thead-light" :data="positions" :max-height="250" ref="multipleTable"
          @selection-change="handleSelectionChange">
          <!-- 多选 -->
          <el-table-column type="selection" width="60" :selectable="selectable" key="id">
          </el-table-column>
          <el-table-column prop="position_name" label="全选">
          </el-table-column>
        </el-table>
      </el-form-item>
      <el-form-item label="报名时间：" prop="joinable_times">
        <el-date-picker v-model="form.joinable_times" type="datetimerange" range-separator="-"
          start-placeholder="开始时间" end-placeholder="结束时间" value-format="timestamp"
                        format="yyyy-MM-dd HH:mm"
                        :picker-options="pickerOptions" @change="handleSelectTime" time-arrow-control>
        </el-date-picker>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button size="small" @click="openDialog = false">取消</el-button>
      <el-button size="small" type="primary" @click="submit('Form')">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { formatDate } from "@/base/utils/tool";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    timeArea: {
      type: [Array, Object],
    },
    positions: {
      type: [Array, Object],
    },
    //职务表单数据
    stagesForm: {
      type: Object,
    },
    // 已选择职务数据
    selected: {
      type: Array,
    },
  },
  data() {
    return {
      loading: false, //加载
      rules: {
        position: [
          { required: true, validator: this.PositionPass, trigger: "change" },
        ],
        joinable_times: [
          { required: true, message: "请选择报名时间", trigger: "change" },
        ],
      },
      form: {
        start_time: "",
        end_time: "",
        position: [],
        position_text: "",
        joinable_times: "",
        time_text: "",
      },
      selectArr: [], //勾选的数组id
      limitSelete: false, //是否限制选择
      // 报名时间选择范围设置
      pickerOptions: {
        disabledDate: (time) => {
          return this.selectableDate(time);
        },
      },
    };
  },
  methods: {
    // 判断是否可选
    selectable(row, index) {
      if (this.limitSelete) {
        if (this.selectedArr.findIndex((item) => item === row.id) !== -1) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        let positionTextArr = [];
        this.selectArr = [];
        selection.forEach((element) => {
          this.selectArr.push(element.id);
          positionTextArr.push(element.position_name);
        });
        this.form.position = this.selectArr;
        this.form.position_text = positionTextArr.join("、");
      }
    },
    // 设置时间选择范围
    selectableDate(time) {
      return (
        time.getTime() < this.timeArea[0] || time.getTime() > this.timeArea[1]
      );
    },
    // 选择时间
    handleSelectTime(e) {
      let start_time = formatDate(e[0], "YYYY-MM-DD H:m");
      let end_time = formatDate(e[1], "YYYY-MM-DD H:m");
      this.form.start_time = e[0] / 1000;
      this.form.end_time = e[1] / 1000;
      this.form.time_text = start_time + " - " + end_time;
      console.log(this.form.time_text, e);
    },
    // 职务选择验证
    PositionPass(rule, value, callback) {
      if (value && value.length) {
        callback();
      } else {
        callback(new Error("请选择职务"));
      }
    },
    // 确定
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("change", { ...this.form });
          this.$refs.multipleTable.clearSelection();
          if (this.limitSelete) {
            this.$refs[formName].resetFields();
          }
          this.openDialog = false;
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
    },
  },
  watch: {
    stagesForm: {
      deep: true,
      handler(val) {
        this.form = { ...val };
        if (val.position.length) {
          this.limitSelete = false;
          val.position.forEach((row) => {
            this.positions.forEach((element) => {
              if (row === element.id) {
                this.$nextTick(() => {
                  this.$refs.multipleTable.toggleRowSelection(element);
                });
              }
            });
          });
        } else {
          this.limitSelete = true;
          this.$nextTick(() => {
            this.$refs.multipleTable.clearSelection();
            this.$refs.Form.resetFields();
          });
        }
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    selectedArr() {
      return this.selected;
    },
  },
};
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.thead-light {
  width: 400px;
  border: 1px solid #e9e9e9;
}
</style>
