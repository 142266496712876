<template>
  <div>
    <div class="add-subjects">
      <div class="subject-list" v-show="myValue.length !== 0">
        <draggable
          v-model="myValue"
          animation="500"
          @end="changeSort"
          draggable=".draggable"
        >
          <div
            class="single-subject draggable"
            v-for="(subject, index) in myValue"
            :key="index"
          >
            <el-form
              :model="myValue[index]"
              :rules="rules"
              label-width="90px"
              @submit.native.prevent
            >
              <div class="item">
                <!-- 操作栏 -->
                <div class="subject-btn">
                  <img src="../../assets/images/operation.png" alt="" />
                  <i @click="removeLine(index)" class="el-icon-delete"></i>
                </div>
                <div class="content">
                  <el-form-item v-if="isVenue" style="margin-bottom: 20px" label="活动地址：">
                    <el-radio-group
                      :disabled="!isVenue"
                      v-model="myValue[index].address_type"
                      @change="isVenueChange(index)"
                      placeholder="请选择活动区域"
                    >
                      <el-radio :label="1"> 选择已有场地 </el-radio>
                      <el-radio :label="0">自定地址</el-radio>
                    </el-radio-group>
                    <p class="info" v-if="!isVenue">
                      <i class="el-icon-info"></i
                      >暂未开通此插件，请开通此插件后再进行选择
                    </p>
                  </el-form-item>
                  <el-form-item
                    v-if="myValue[index].address_type && isVenue"
                    prop="address"
                    label="选择场地："
                  >
                    <div class="label">
                      <el-button
                        class="company-address"
                        @click="handleAddvenueSelect(index)"
                        icon="el-icon-location-outline"
                      >
                        <template v-if="myValue[index].address_name"
                          >{{ myValue[index].address_name }}(</template
                        >
                        {{ myValue[index].address || '请选择场地' }}
                        <template v-if="myValue[index].address_name"
                          >)</template
                        >
                      </el-button>
                    </div>
                  </el-form-item>
                  <el-form-item v-else prop="address" label="选择地址：">
                    <div class="label">
                      <el-button
                        class="company-address"
                        @click="handleAddressSelect(index)"
                        icon="el-icon-location-outline"
                      >
                        <template v-if="myValue[index].address_name"
                          >{{ myValue[index].address_name }}(</template
                        >
                        {{ myValue[index].address || '请选择活动地址' }}
                        <template v-if="myValue[index].address_name"
                          >)</template
                        >
                      </el-button>
                    </div>
                  </el-form-item>
                  <el-form-item prop="activityTime" label="活动时间：">
                    <div v-if="myValue[index].activityTime" class="label">
                      <el-date-picker
                        v-model="myValue[index].activityTime"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        value-format="timestamp"
                        format="yyyy-MM-dd HH:mm"
                        :picker-options="pickerActivityOptions"
                        @input="handleSelectTime(index, $event)"
                        time-arrow-control
                        :key="myValue[index].start_time"
                      >
                      </el-date-picker>
                      <el-button
                        v-if="activityTime"
                        @click="autoTime(myValue[index])"
                        type="text"
                        style="margin-left: 10px"
                        >使用活动时间</el-button
                      >
                    </div>
                  </el-form-item>
                  <el-form-item prop="remark" label="备注：">
                    <div class="label">
                      <el-input
                        height="80px"
                        type="textarea"
                        class="textarea"
                        v-model="myValue[index].remark"
                        placeholder="请输入内容"
                      ></el-input>
                    </div>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
        </draggable>
      </div>
      <p class="img-tips" v-if="myValue && myValue.length">提示：拖拽可以调整排序</p>
      <el-button
        class="subject-add-btn"
        type="primary"
        size="small"
        @click="addLine"
        >添加地址</el-button
      >
    </div>
    <AddressSelector
      v-model="openAddressSelector"
      @change="handleChangeAddress"
      :detailForm="currentForm"
    />
    <AddvenueSelector
      v-if="isVenue"
      v-model="openAddvenueSelector"
      @change="handleChangeAddvenue"
    />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import AddressSelector from '../../../common/components/AddressSelector.vue'
import AddvenueSelector from './AddvenueSelector/index.vue'
import { UniversalTransition } from 'echarts/features'
export default {
  name: 'ActivityAddress',
  components: { draggable, AddressSelector, AddvenueSelector },
  model: {
    prop: 'value',
    event: 'changeValue',
  },
  props: {
    value: {
      type: Array,
    },
    activity_end_time: {
      type: Number | String,
    },
    activity_start_time: {
      type: Number | String,
    },
    // 饿了么格式，例如：[ 1710345600000, 1713110400000 ]
    activityTime: {
      type: [Array, Object, String], // 默认未填是''，手动清空是null，已选是[x,x]
      default: null,
    },
    type: {
      type: Number,
    },
  },
  data() {
    return {
      loading: true,
      // 活动时间选择范围设置
      pickerActivityOptions: {
        disabledDate: (time) => {
          return this.selectableDate(time)
        },
      },
      myValue: [],
      // 打开场地窗
      openAddvenueSelector: false,
      // 打开地址弹窗
      openAddressSelector: false,
      // 当前操作的form
      currentForm: {
        lat: '',
        lng: '',
        address: '',
        remark: '',
        start_time: -1,
        end_time: -1,
        activityTime: [],
        addressName: '',
      },
      // 当前操作项的索引
      currentI: -1,
      rules: {
        address: [
          { required: true, message: '请输入活动地址', trigger: 'change' },
        ],
      },
    }
  },
  watch: {
    value: {
      handler(val) {
        // console.log(val);

        this.myValue = val || []
        if (this.myValue) {
          this.myValue.forEach((item) => {
            item.activityTime = []

            if (item.start_time !== -1 && item.end_time !== -1) {
              // console.log(item)
              item.activityTime[0] = item.start_time * 1000
              item.activityTime[1] = item.end_time * 1000
            }
          })
        }
      },
      immediate: true,
      // deep: true,
    },
  },
  computed: {
    isVenue() {
      return this.$store.getters.tenant.features.includes('venue')
    },
  },
  created() {
    // console.log(this.myValue);
  },
  mounted() {
    if (this.myValue) {
      this.myValue.forEach((item) => {
        item.activityTime = []
        if (item.start_time && item.end_time) {
          item.activityTime[0] = item.start_time * 1000
          item.activityTime[1] = item.end_time * 1000
        }
      })
    }
  },
  methods: {
    /**
     * 读取总的活动时间
     * @param target
     */
    autoTime(target) {
      if (!this.activityTime) return
      target.activityTime = this.activityTime
      target.start_time = this.activityTime[0] / 1000
      target.end_time = this.activityTime[1] / 1000
    },
    // 移除
    removeLine(i) {
      this.$confirm('是否删除此地址?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.myValue.splice(i, 1)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {})
    },
    // 添加
    addLine() {
      this.myValue.push({
        lat: '',
        lng: '',
        address: '',
        remark: '',
        start_time: -1,
        end_time: -1,
        activityTime: [],
        address_type: 0,
      })

      if (this.activityTime) {
        this.autoTime(this.myValue[this.myValue.length - 1]) // 自动填入
      }

      this.$emit('changeValue', this.myValue)
    },
    // 打开地址选择器
    handleAddressSelect(i) {
      console.log(this.myValue)
      // 修改当前操作的form
      this.currentForm = this.myValue[i]
      this.currentI = i
      console.log(this.currentForm)
      // 打开地址弹窗
      this.openAddressSelector = true
    },
    isVenueChange(i) {
      console.log(this.tenantVersion)
      this.myValue[i].address_name = ''
      this.myValue[i].lat = ''
      this.myValue[i].lng = ''
      this.myValue[i].address = ''
    },
    handleAddvenueSelect(i) {
      this.currentForm = this.myValue[i]
      this.currentI = i
      console.log(this.currentForm)
      // 打开地址弹窗
      this.openAddvenueSelector = true
    },
    // 场地调整触发
    handleChangeAddvenue(value) {
      this.myValue[this.currentI] = Object.assign(
        this.myValue[this.currentI],
        value
      )
    },
    // 地址改变
    handleChangeAddress(value) {
      // this.myValue[this.currentI] = value;
      this.myValue[this.currentI] = Object.assign(
        this.myValue[this.currentI],
        value
      )
    },
    // 选择活动时间
    handleSelectTime(i, time) {
      this.myValue[i].activityTime = null
      if (time && time.length) {
        this.myValue[i].start_time = time[0] / 1000
        this.myValue[i].end_time = time[1] / 1000
        this.myValue[i].activityTime = time
      } else {
        this.myValue[i].start_time = -1
        this.myValue[i].end_time = -1
        this.myValue[i].activityTime = []
      }
      console.log(this.myValue[i])
    },
    // 顺序改变
    changeSort() {
      this.$emit('changeValue', this.myValue)
    },
    // 设置活动时间选择范围，禁用返回true
    selectableDate(time) {
      // console.log(this.$props.activity_end_time);
      if (this.$props.activity_end_time && this.$props.type) {
        const endSource = this.$props.activity_end_time * 1000
        const startSource = this.$props.activity_start_time * 1000

        const startSourceDate = new Date(startSource)
        const endSourceDate = new Date(endSource)

        // 从零点开始
        const startDate = new Date(
          startSourceDate.getFullYear(),
          startSourceDate.getMonth(),
          startSourceDate.getDate()
        )
        // 结束日期当天全部可以选，不然日期会被禁用的
        const endDate = new Date(
          endSourceDate.getFullYear(),
          endSourceDate.getMonth(),
          endSourceDate.getDate(),
          23,
          59,
          59
        )

        return (
          time.getTime() > endDate.getTime() ||
          time.getTime() < startDate.getTime()
        )
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.add-subjects {
  .img-tips {
    width: 194px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #999;
    line-height: 12px;
    margin-top: 10px;
  }

  .subject-list {
    width: 700px;

    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: #f5f7fa;

    .single-subject {
      display: flex;
      justify-content: space-between;

      .item {
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 5px;
        color: #909399;

        .subject-btn {
          padding: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid rgba(244, 244, 245, 1);

          .el-icon-delete {
            cursor: pointer;
          }
        }

        .content {
          margin: 22px 12px;

          .label {
            display: flex;
            margin-bottom: 20px;
            color: #606266;

            span {
              width: 90px;
              text-align: right;
              margin-right: 8px;
            }

            .tips {
              width: 194px;
              display: flex;
              align-items: center;
              font-size: 12px;
              color: #999;
              line-height: 12px;
              margin-left: 10px;
            }

            .el-input {
              flex: 1;
              max-width: 400px;
            }

            .textarea {
              flex: 1;
              max-width: none;
            }

            ::v-deep .el-textarea__inner {
              height: 80px;
            }
          }
        }
      }

      .flex {
        display: flex;
        padding-top: 10px;

        span {
          width: 15px;
          display: inline-block;
          margin-right: 10px;
        }
      }
    }

    .subject-del-btn {
      width: 30px;
      text-align: right;
      cursor: pointer;
    }
  }

  .img-tips + .subject-add-btn {
    margin-top: 10px;
  }
}

.el-icon-delete:hover {
  color: red;
}

.el-form {
  width: 100%;
  margin: 5px 0;
}

.company-address {
  @include nowrap();
}
</style>
