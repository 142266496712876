<template>
  <div class="main-page">
    <el-form
      size="small"
      :model="ActivityForm"
      label-position="right"
      label-width="100px"
      class="form small-form"
      v-if="ActivityForm"
      v-loading="loading"
      @submit.native.prevent
    >
      <div class="tips-box" v-if="$route.params.type === 'add'">
        <i class="el-icon-success"></i>
        <p>活动已创建</p>
      </div>
      <div class="modular">
        <div class="modular-content">
          <el-form-item label="显示设置：" prop="circle_post_enable">
            <div class="form-box">
              <el-radio-group v-model="ActivityForm.is_show">
                <el-radio :label="1">显示</el-radio>
                <el-radio :label="0">隐藏</el-radio>
              </el-radio-group>
            </div>

            <div class="tips">
              设置是否显示活动入口，如不需要勾选「不显示」，后续可以在活动列表开启
            </div>
          </el-form-item>
          <el-form-item v-if="isWxApplet" label="小程序入口：" prop="mp_qrcode">
            <img class="mp_qrcode" :src="ActivityForm.mp_qrcode" alt="" />
          </el-form-item>
          <el-form-item label="H5 微网站入口：" prop="h5_url">
            <img class="mp_qrcode" :src="ActivityForm.h5_qrcode" alt="" />

            <div class="form-box">
              <el-link type="primary" @click="handleCopyText" :underline="false"
                >复制链接</el-link
              >
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import { copyText } from "@/base/utils/tool";
export default {
  props: ["form", "id", "is_show"],
  model: {
    prop: "form",
    event: "updateForm",
  },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    "ActivityForm.is_show": {
      handler(newVal, oldVal) {
        this.$emit("changeShow", newVal);
        console.log(newVal);
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    // 复制链接
    handleCopyText() {
      copyText(this.ActivityForm.h5_url)
        .then((res) => {
          this.$message.success("复制成功");
        })
        .catch((err) => {
          this.$message.success("复制失败");
        });
    },
  },
  computed: {
    ActivityForm: {
      get() {
        return this.form;
      },
      set(val) {
        this.$emit("updateForm", val);
      },
    },
    isCycOrganization() {
      return this.$store.getters.tenant.features.includes('cyc_organization')
    },
    isWxApplet() {
      return this.$store.getters.tenant.features.includes('wx_applet')
    }
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.tips-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  background-color: #e8fbf0;
  margin-bottom: 25px;
  i.el-icon-success {
    margin-right: 8px;
    color: #13ce66;
  }

  p {
    font-size: 14px;
    color: #13ce66;
  }
}
.tips {
  font-size: 12px;
  color: #c0c4cc;
  line-height: 24px;
}
.modular-content {
  margin-left: 11px;
}
.mp_qrcode {
  width: 210px;
  height: 210px;
  vertical-align: middle;
}
::v-deep .el-form-item__content {
  margin-left: 120px !important;
}
.form-box {
  margin-left: 15px;
}
</style>
