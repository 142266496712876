<template>
  <div class="step-box">
    <!-- 线上报名步骤条 -->
    <el-steps   v-if="!activityType" :active="stepStatus" align-center>
      <el-step  v-for="(item, i) in onLine" :key="i">
        <template #title>
          <div class="step-title" @click="changeStatus(i)">{{ item.name }}</div>
        </template>
        <template #icon>
          <div class="step-icon" @click="changeStatus(i)"></div>
        </template>
      </el-step>
    </el-steps>
 
    <!-- 线下报名步骤条 -->
    <el-steps v-else :active="stepStatus" align-center>
      <el-step v-for="(item, i) in unLine" :key="i">
        <template #title>
          <div class="step-title" @click="changeStatus(i)">{{ item }}</div>
        </template>
        <template #icon>
          <div class="step-icon" @click="changeStatus(i)"></div>
        </template>
      </el-step>
    </el-steps>
    <!-- <el-button @click="next">下一步</el-button>
    <el-button @click="preNext">上一步</el-button> -->
  </div>
</template>
<script>


export default {
  name: "ActivityStep",
  model: {
    prop: "status",
    event: "changeStatus",
  },
  props: {
    status: {
      type: Number,
      default: 1,
    },
    // 活动类型， 0线上，1线下
    activityType: {
      type: Number,
      default: 0,
    },
    // 操作类型， add新增，edit编辑
    type: {
      type: String,
      default: "add",
    },
    // 是否禁用步骤条点击
    isDisabled: {
      type: Boolean,
      default: true,
    },
  },
  components: {  },
  created() {
    // 根据编辑状态，显示对应步骤名称
    if (this.$props.type !== "add") {
      this.onLine[2].name = "分享";
      this.unLine[3] = "分享";
    }
  },
  computed: {
    // 步骤条状态 初始状态是 0
    stepStatus: {
      get() {
        return this.$props.status - 1;
      },
      set(val) {
        this.$emit("changeStatus", val + 1);
      },
    },
  },
  watch: {
    activityType: {
      handler(val) {
        this.$forceUpdate();
      },
      immediate: true,
    },
  },

  data() {
    return {
      // 线上
      onLine: [
        { name: "基本信息", show: true },
        { name: "其他设置", show: true },
        { name: "完成", show: true },
      ],
      // 线下
      unLine: ["基本信息", "报名设置", "其他设置", "完成"],
    };
  },
  methods: {
    next() {
      this.stepStatus++;
    },
    preNext() {
      this.stepStatus--;
    },
    changeStatus(current) {
      // 新增不许切换步骤条, 判断是否禁用
      if (this.$props.type === "add" || this.$props.isDisabled) {
        return;
      }
      // this.stepStatus = current;
      // 加一才是真实的步骤  + 1
      this.$emit("clickStatus", current + 1);
    },
  },
};
</script>
<style lang="scss" scoped>
// 步骤框样式调整
.step-icon {
  width: 100%;
  height:100%;
  cursor: pointer;
}
.step-title {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}
::v-deep .is-process {
  color: #3576ff !important;
  border-color: #3576ff !important;
}
::v-deep .el-step__head.is-finish {
  .el-step__line {
    background-color: #3576ff !important;
  }
  
}
::v-deep .el-step__icon-inner {
    display: none !important;
  }

  ::v-deep .el-steps.el-steps--horizontal {
    .el-step__icon {
      width: 16px !important;
      height: 16px !important;
    }
    .el-step.is-horizontal .el-step__line {
      top:7px;
    }
    .el-step__head.is-finish {
      .el-step__icon {
        background-color: #3576ff;
    }
    }
  }
</style>
