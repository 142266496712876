<!--
 * @Author: dongjia
 * @Date: 2022-01-18 14:57:39
 * @LastEditTime: 2022-01-18 18:20:25
 * @LastEditors: aleaner
 * @Description: 添加表单选项弹窗
 * @FilePath: \activity\components\ActivityList\ActivityJoinForms\AddOptionsDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog :title="`${titleText}选项`" :visible.sync="open" width="400px" :show-close="true"
    class="dialog-vertical" append-to-body>
    <el-form size="small" :model="optionForm" :rules="rules" ref="optionForm" label-width="90px" @submit.native.prevent>
      <el-form-item label="选项名称：" prop="text">
        <el-input style="width: 260px" v-model="optionForm.text" placeholder="请填写选项名称">
        </el-input>
      </el-form-item>
      <el-form-item label="额外费用：" prop="pay_amount">
        <el-input style="width: 260px" placeholder="请输入" v-model.number="optionForm.pay_amount">
          <template slot="append">
            <el-select v-model="optionForm.pay_type" style="width: 90px">
              <el-option v-for="item in payTypeOptions" :disabled="item.label === '积分'" :key="item.value"
                :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" @click="submit('optionForm')">确定</el-button>
      <el-button @click="open = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  model: {
    prop: "openDialog",
    event: "close",
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      required: true,
    },
    titleText: {
      type: String,
      defualt: "添加",
    },
  },
  data() {
    return {
      optionForm: {
        text: "",
        pay_amount: 0, // 支付数量
        pay_type: 0, // 支付方式：0-金额、1-积分
      },
      rules: {
        text: [{ required: true, message: "请输入选项内容", trigger: "blur" }],
      },
      payTypeOptions: [
        { label: "金额", value: 0 },
        { label: "积分", value: 1 },
      ],
    };
  },
  watch: {
    formData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          this.$refs.optionForm.resetFields();
          this.optionForm = { ...this.optionForm, ...this.formData };
        });
      },
    },
  },
  computed: {
    open: {
      get() {
        return this.openDialog;
      },
      set(val) {
        this.$emit("close", val);
      },
    },
  },
  methods: {
    submit(formName) {
      let formData = JSON.parse(JSON.stringify(this.optionForm));
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("optionsChange", formData);
          this.open = false;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>