<!--
 * @Author: dongjia
 * @Date: 2022-01-18 15:18:37
 * @LastEditTime: 2022-01-18 17:57:19
 * @LastEditors: aleaner
 * @Description: 表单选项列表
 * @FilePath: \activity\components\ActivityList\ActivityJoinForms\ActivityJoinFormOptions.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="confirm-content">
    <el-button type="primary" size="small" @click="AddOption">+ 添加选项</el-button>
    <p class="stage-tip" style="margin-bottom:0px;"><span style="margin-right: 5px"
        class="el-icon-warning"></span>点击拖动调整排序；如选项涉及额外费用将与报名费用合并收取，不填或填 0 时为无需支付额外费用</p>
    <el-table :data="optionList" class="thead-light" stripe style="width: 100%;margin-top: 12px;"
      id="sortTabled" row-key="timestamp">
      <!-- 拖拽排序 -->
      <el-table-column label="拖拽排序" :width="90">
        <span class="el-icon-sort my-handle cursor-pointer" />
      </el-table-column>
      <!-- 操作 -->
      <el-table-column label="操作" :width="120" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="EditRow(scope.row,scope.$index)">编辑</el-button>
          <el-button type="text" size="small" @click="DeleteRow(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
      <template v-for="(th, index) in thead">
        <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
          :min-width="th.minWidth || ''" :key="index">
          <template slot-scope="{row}">
            <!-- 额外费用 -->
            <span
              v-if="th.prop === 'pay_amount'">{{row.pay_type?`${row[th.prop] || 0}积分`:`￥${row[th.prop] || 0}`}}</span>
            <!-- 普通 -->
            <span v-else>
              {{ row[th.prop] | placeholder }}
            </span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <AddOptionsDialog v-model="openAddOptionForm" :formData="optionsForm" :titleText="dialogTitle"
      @optionsChange="updateOptions" />
  </div>
</template>

<script>
import Sortable from "@/modules/page-renovation/js/Sortable.min.js";
import AddOptionsDialog from "./AddOptionsDialog.vue";
export default {
  props: {
    formOptions: {
      type: [Array, Object],
    },
  },
  data() {
    return {
      thead: [
        { label: "选项名称", prop: "text", minWidth: 110 },
        { label: "额外费用", prop: "pay_amount", minWidth: 90 },
      ],
      optionsForm: {
        text: "",
        pay_amount: 0, // 支付数量
        pay_type: 0, // 支付方式：0-金额、1-积分
        timestamp: 0,
      },
      currentIndex: -1,
      openAddOptionForm: false,
      dialogTitle: "添加",
    };
  },
  watch: {
    openAddOptionForm(val) {
      if (!val) {
        this.optionsForm = {
          text: "",
          pay_amount: 0, // 支付数量
          pay_type: 0, // 支付方式：0-金额、1-积分
          timestamp: 0,
        };
      }
    },
  },
  mounted() {
    this.drag();
  },
  methods: {
    // 拖拽排序
    drag() {
      const el = document.querySelectorAll(
        "#sortTabled .el-table__body > tbody"
      )[0];
      Sortable.create(el, {
        disabled: false,
        handle: ".my-handle",
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) {},
        onEnd: (e) => {
          this.$nextTick(() => {
            let arr = this.optionList;
            arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]); // 数据处理
          });
        },
      });
    },
    // 点击添加
    AddOption() {
      this.dialogTitle = "添加";
      this.currentIndex = -1;
      this.optionsForm = {
        text: "",
        pay_amount: 0, // 支付数量
        pay_type: 0, // 支付方式：0-金额、1-积分
      };
      this.openAddOptionForm = true;
    },
    // 编辑
    EditRow(row, index) {
      this.dialogTitle = "编辑";
      this.currentIndex = index;
      this.optionsForm = row;
      this.openAddOptionForm = true;
    },
    // 新增、编辑弹窗确定时
    updateOptions(data) {
      if (this.currentIndex == -1) {
        this.optionList.push({ ...data, timestamp: new Date().getTime() });
      } else {
        this.optionList.splice(this.currentIndex, 1, data);
      }
    },
    // 点击移除
    DeleteRow(i) {
      this.optionList.splice(i, 1);
    },
  },
  computed: {
    optionList: {
      get() {
        return this.formOptions;
      },
      set(val) {
        this.$emit("update:optionList", val);
      },
    },
  },
  components: { AddOptionsDialog },
};
</script>

<style lang="scss" scoped>
.stage-tip {
  margin-top: 12px;
  line-height: 1.5;
  color: #c0c4cc;
  font-size: 12px;
}
</style>