<template>
  <div class="confirm-content">
    <el-table :data="stageList" class="thead-light" stripe style="width: 100%">
      <!-- 序号 -->
      <el-table-column width="100" type="index" :index="indexMethod" label="序号"></el-table-column>
      <!-- 操作 -->
      <el-table-column label="操作" :width="120" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="EditRow(scope.row,scope.$index)">编辑</el-button>
          <el-button type="text" style="color:#FF4949" size="small" @click="DeleteRow(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
      <template v-for="(th, index) in thead">
        <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
          :min-width="th.minWidth || ''" :key="index">
          <template slot-scope="scope">
            <!-- 普通 -->
            <span>
              {{ scope.row[th.prop] | placeholder }}
            </span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <el-button class="bottom-btn opacity-btn" @click="AddStage">+ 添加
    </el-button>
    <stage-price-dialog v-model="OpenSelectPosition" :positions="positionList" :stagesForm="stagesForm"
      :selected="selectedArr" @change="handleSelect"></stage-price-dialog>
  </div>
</template>

<script>
import StagePriceDialog from "./StagePriceDialog.vue";
export default {
  props: {
    stages: {
      type: [Array, Object],
    },
    positions: {
      type: [Array, Object],
    },

  },
  data() {
    return {
      thead: [
      { label: "架构/会内职务", prop: "position_text", minWidth: 200 },
        { label: "报名费用(元)", prop: "price", minWidth: 200 },
      ],
      stagesForm: {
        position_ids: [],
        position_text: "",
        price:0,
        price_type:0

      },
      selectedArr: [],
      currentIndex: -1,
      OpenSelectPosition: false,
    };
  },
  methods: {
    // 序号计算
    indexMethod(index) {
      return index + 1;
    },
    // 点击添加
    AddStage() {
      let selected = [];
      this.stageList.forEach((element) => {
        element.position_ids.forEach((item) => {
          selected.push(item);
        });
      });
      console.log(this.stageList);
      this.selectedArr = selected;
      this.currentIndex = -1;
      this.stagesForm = {
        position_ids: [],
        position_text: "",
        price:0,
        price_type:0
      };
      this.OpenSelectPosition = true;
    },
    // 编辑
    EditRow(row, index) {
      this.selectedArr = [];
      this.currentIndex = index;
      this.stagesForm = row;
      this.OpenSelectPosition = true;
    },
    // 点击移除
    DeleteRow(i) {
      this.stageList.splice(i, 1);
    },
    // 点击确认
    handleSelect(e) {
      console.log("position:", e);
      if (this.currentIndex !== -1) {
        this.stages.splice(this.currentIndex, 1, e);
      } else {
        this.stages.push(e);
      }
    },
  },
  computed: {
    stageList: {
      get() {
        return this.stages;
      },
      set(val) {
        this.$emit("update:stageList", val);
      },
    },
    positionList() {
      return this.positions;
    },
  },
  components: { StagePriceDialog },
};
</script>

<style lang="scss" scoped>
.bottom-btn {
  width: 100%;
  margin-top: 8px;
  border: 1px dashed rgba(217, 217, 217, 1);
  font-size: 16px;
  padding: 8px 0;
}

.bottom-btn:hover {
  border: 1px dashed #3576ff;
}
</style>