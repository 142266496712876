<template>
  <div class="confirm-content">
    <el-table :data="planList" class="thead-light" stripe style="width: 100%" id="sortTable"
      row-key="timestamp">
      <!-- 操作 -->
      <el-table-column label="操作" :width="70" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="DeleteRow(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
      <!-- 拖拽排序 -->
      <el-table-column label="拖拽排序" :width="90">
        <span class="el-icon-sort my-handle cursor-pointer" />
      </el-table-column>
      <template v-for="(th, index) in thead">
        <el-table-column :prop="th.prop" :label="th.label" :min-width="th.minWidth || ''" :key="index">
          <template slot-scope="scope">
            <!-- 时间 -->
            <!--            <el-time-picker style="width: 90%" v-if="th.prop === 'display_time'" is-range format="HH:mm"-->
            <!--              value-format="timestamp" v-model="scope.row[th.prop]" range-separator="-"-->
            <!--              start-placeholder="开始时间" end-placeholder="结束时间" @change="timeSelect($event,scope.$index)">-->
            <!--            </el-time-picker>-->
            <el-input v-if="th.prop === 'time_text'" v-model="scope.row[th.prop]" placeholder="请输入议程时间" />
            <!-- 议程地址 -->
            <div v-else-if="th.prop === 'planAddress'"
              :class="['list-address',scope.row[th.prop].address?'':'default']"
              @click="handleAddAddress(scope.row[th.prop],scope.$index)">
              {{scope.row[th.prop].address || "选择地址"}}
            </div>
            <!-- 活动议程
            <el-input v-else-if="th.prop === 'plan'" type="text" v-model="scope.row[th.prop]"></el-input> -->
            <!-- 普通 -->
            <span v-else>
              <el-input type="textarea" v-model="scope.row[th.prop]" />
            </span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <AddressSelector v-model="openAddressSelector" @change="handleChangeAddress" :detailForm="planAddress" />
    <el-button class="bottom-btn opacity-btn" @click="AddPlan">+ 添加议程
    </el-button>
  </div>
</template>

<script>
import { formatDate } from "@/base/utils/tool";
import Sortable from "@/modules/page-renovation/js/Sortable.min.js";
import AddressSelector from "../../../common/components/AddressSelector.vue";
export default {
  components: { AddressSelector },
  props: {
    plans: {
      type: [Array, Object],
    },
  },
  data() {
    return {
      thead: [
        // { label: "时间", prop: "date_text", minWidth: 190 },
        { label: "时间", prop: "time_text", minWidth: 190 },
        { label: "议程", prop: "plan", minWidth: 100 },
        { label: "简介", prop: "description", minWidth: 150 },
        { label: "地址", prop: "planAddress", minWidth: 130 },
      ],
      planForm: {
        // date_text: "",
        // display_time: "",
        time_text: "",
        plan: "",
        description: "",
        planAddress: {
          address: "",
          lat: "",
          lng: "",
          cs: "BD09", // 坐标系
        },
      },
      planList: [],
      planAddress: {
        address: "",
        lat: "",
        lng: "",
        cs: "BD09", // 坐标系
      },
      currentIndex: -1,
      openAddressSelector: false,
    };
  },
  watch: {
    planList: {
      deep: true,
      handler(val) {
        this.$emit("change", val);
      },
    },
    plans: {
      deep: true,
      handler(val) {
       if(val.length == this.planList.length) {
        return
       }else {
        this.planList = val
       }
      },
    },
  },
  created() {
    let _arr = [];
    this.planList = JSON.parse(JSON.stringify(this.plans));
    this.planList.map((el, i) => {
      if (!el.timestamp) {
        _arr.push(
          Object.assign({}, el, { timestamp: new Date().getTime() + i })
        );
      } else if (!el.planAddress) {
        _arr.push(
          Object.assign({}, el, {
            planAddress: {
              address: "",
              lat: "",
              lng: "",
              cs: "BD09", // 坐标系
            },
          })
        );
      } else {
        _arr.push(el);
      }
    });
    this.planList = _arr;
  },
  mounted() {
    this.drag();
  },
  methods: {
    // 拖拽排序
    drag() {
      const el = document.querySelectorAll(
        "#sortTable .el-table__body > tbody"
      )[0];
      Sortable.create(el, {
        disabled: false,
        handle: ".my-handle",
        ghostClass: "sortable-ghost",
        setData: function (dataTransfer) {},
        onEnd: (e) => {
          this.$nextTick(() => {
            let arr = this.planList;
            arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]); // 数据处理
          });
        },
      });
    },
    // 点击添加
    AddPlan() {
      this.planList.push({ ...this.planForm, timestamp: new Date().getTime() });
    },
    // 点击添加地址
    handleAddAddress(data, index) {
      this.planAddress = data;
      this.currentIndex = index;
      this.openAddressSelector = true;
    },
    // 地址调整触发
    handleChangeAddress(val) {
      this.planList[this.currentIndex].planAddress = val;
    },
    // 点击移除
    DeleteRow(i) {
      this.planList.splice(i, 1);
    },
    // 时间选择处理
    timeSelect(e, i) {
      this.planList[i].time_text =
        formatDate(e[0], "H:m") + " ~ " + formatDate(e[1], "H:m");
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-btn {
  width: 100%;
  margin-top: 8px;
  border: 1px dashed rgba(217, 217, 217, 1);
  font-size: 16px;
  padding: 8px 0;
}

.bottom-btn:hover {
  border: 1px dashed #3576ff;
}

.list-address {
  cursor: pointer;
  &.default,
  &:hover {
    color: #3576ff;
  }
}
</style>